import { defineStore } from 'pinia';
import router from './../router';

const ruta_api = process.env.VUE_APP_ROOT_API;

export const useAuth = defineStore({
  id: 'auth',
  state: () => ({
      token: localStorage.getItem('token') || '',
      user: JSON.parse(localStorage.getItem('user')),
      returnUrl: null
  }),
  actions: {
      // signup user
      async userSignup(data) {
          // to use fetch api

          const res = await fetch('http://localhost:8000/api/register', {
              method: 'POST',
              body: JSON.stringify(data),
              headers: {
                  'Content-Type': 'application/json'
              }
          });
          const output = await res.json();
          if (output.success) {
              alert(output.message);
              data.name = '';
              data.email = '';
              data.password = '';
          } else {
              alert(output.message);
          }
      },
      async userLogin(data) {

          const res = await fetch(ruta_api +'/login', {
              method: 'POST',
              body: JSON.stringify(data),
              headers: {
                  'Content-Type': 'application/json'
              }
          });
          const output = await res.json();
          if (output.success) {
              localStorage.setItem('token', output.token);
              this.token = output.token;

                // update pinia state
              this.user = output.user;
              this.user.permissions = output.permissions;

              //alert(output.message)

              // store user details and jwt in local storage to keep user logged in between page refreshes
              localStorage.setItem('user', JSON.stringify(output.user));

              // redirect to previous url or default to home page
              router.push(this.returnUrl || '/');

          } else {
              alert(output.mensaje)

          }
      },

      async getUser() {
          const res = await fetch('http://localhost:8000/api/user', {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + localStorage.getItem('token')
              }
          });
          const output = await res.json();
          this.user = output.user

      },
      userLogout() {
          this.token = '';
          this.user = '';
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          router.push({ name: 'login' });
      }
  }
})