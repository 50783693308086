<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
      <PanelMenu :model="panelMenuitems" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>
    <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" />
    <transition name="layout-mask">
      <div
        class="layout-mask p-component-overlay"
        v-if="mobileMenuActive"
      ></div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppConfig from "./AppConfig.vue";
import AppFooter from "./AppFooter.vue";
import { useAuth } from "./stores";

export default {
  emits: ["change-theme"],
  data() {
    return {
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: "MENÚ PRINCIPAL",
          items: [{ label: "INICIO", icon: "pi pi-fw pi-home", to: "/" }],
        },
      ],
      panelMenuitems: [],
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  authService: null,
  created() {
    this.authService = useAuth();
  },
  mounted() {
    let permisosUsuarioAuth = this.authService.user.permissions;

    let permisosAsignados = [];
    //PARAMETROS - Modulo
    //Productos - Menu

    // Submenus
    let menu = [];

    let submenusItems = [];

    if (
      "Grupo Crear" /* &&
      "Grupo Editar" &&
      "Grupo Ver Detalle" &&
      "Grupo Eliminar" */ in permisosUsuarioAuth
    ) {
      submenusItems.push({
        label: "Grupos de Productos",
        icon: "pi pi-fw pi-sitemap",
        to: "/grupos",
      });
    }

    if (
      "SubGrupo Crear" /*  &&
      "SubGrupo Editar" &&
      "SubGrupo Ver Detalle" &&
      "SubGrupo Eliminar" */ in permisosUsuarioAuth
    ) {
      submenusItems.push({
        label: "SubGrupos de Productos",
        icon: "pi pi-fw pi-sitemap",
        to: "/subgrupos",
      });
    }

    if (
      "Procedencia Crear" /* &&
      "Procedencia Editar" &&
      "Procedencia Ver Detalle" &&
      "Procedencia Eliminar" */ in permisosUsuarioAuth
    ) {
      submenusItems.push({
        label: "Procedencias",
        icon: "pi pi-fw pi-id-card",
        to: "/procedencias",
      });
    }

    if (
      /*  "Laboratorio Listar" && */
      "Laboratorio Crear" in
      /*  "Laboratorio Editar" &&
      "Laboratorio Ver Detalle" &&
      "Laboratorio Eliminar" */ permisosUsuarioAuth
    ) {
      submenusItems.push({
        label: "Administrar Marcas de Insumos",
        icon: "pi pi-briefcase",
        to: "/laboratorios",
      });
    }
    if (
      "Unidad de Medida Crear" /* &&
      "Unidad de Medida Editar" &&
      "Unidad de Medida Eliminar" &&
      "Unidad de Medida Importar" &&
      "Unidad de Medida Exportar" */ in permisosUsuarioAuth
    ) {
      submenusItems.push({
        label: "Unidad de Medida Insumos",
        icon: "pi pi-tag",
        to: "/unidadmedidas",
      });
    }

    if (
      "PaqueteProducto Crear" /* &&
      "PaqueteProducto Editar" &&
      "PaqueteProducto Ver Detalle" &&
      "PaqueteProducto Eliminar" &&
      "PaqueteProducto AgregarProducto" */ in permisosUsuarioAuth
    ) {
      submenusItems.push({
        label: "Paquetes de Productos",
        icon: "pi pi-box",
        to: "/paqueteproductos",
      });
    }
    if (
      "Producto Crear" /* &&
      "Producto Editar" &&
      "Producto Ver Detalle" &&
      "Producto Eliminar" */ in permisosUsuarioAuth
    ) {
      submenusItems.push({
        label: "Administrar Productos y Servicios",
        icon: "pi pi-list",
        to: "/productos",
      });
    }

    //Productos - Menu

    if (submenusItems.length) {
      menu.push({
        label: "Productos",
        icon: "pi pi-fw pi-box",
        items: submenusItems,
      });
    }

    if ("Vehiculos Crear" in permisosUsuarioAuth) {
      menu.push({
        label: "Administrar Vehiculos de Clientes",
        icon: "pi pi-car",
        to: "/vehiculos",
      });
    }

    if (menu.length) {
      permisosAsignados.push({
        label: "PARAMETROS",
        icon: "pi pi-fw pi-table",
        items: menu,
      });
    }

    //FIN MODULO PARAMETROS

    //MODULO DE COMPRAS
    menu = [];
    submenusItems = [];

    if ("Proveedor Realizar Pagos" in permisosUsuarioAuth) {
      submenusItems.push({
        label: "Pago Proveedores",
        icon: "pi pi-money-bill",
        to: "/proveedorpagos",
      });
    }
    if (
      "Proveedor Crear" /* &&
      "Proveedor Editar" &&
      "Proveedor Ver Detalle" &&
      "Proveedor Eliminar" */ in permisosUsuarioAuth
    ) {
      submenusItems.push({
        label: "Registro de Proveedores",
        icon: "pi pi-list",
        to: "/proveedores",
      });
    }
    if (
      "Compra Crear" in
      /* "Compra Ver Detalle" &&
      "Compra Recibir" &&
      "Compra DescargarPDF" &&
      "Compra Ver DetalleRecepcion" &&
      "Compra Anular"  */ permisosUsuarioAuth
    ) {
      menu.push({
        label: "Gestión de Compras de Insumos",
        icon: "pi pi-fw pi-list",
        to: "/compras",
      });
    }

    if (submenusItems.length) {
      menu.push({
        label: "Proveedores",
        icon: "pi pi-fw pi-user-plus",
        items: submenusItems,
      });
    }

    if (menu.length) {
      permisosAsignados.push({
        label: "COMPRAS",
        icon: "pi pi-shopping-bag",
        items: menu,
      });
    }
    //FIN MÓDULO DE COMPRAS

    //MODULO DE CAJA

    menu = [];
    submenusItems = [];

    if (
      "Caja Crear" /*  ||
      "Caja Cerrar" ||
      "Caja Ver Detalle" ||
      "Caja Eliminar" ||
      "Caja DescargarPDF" */ in permisosUsuarioAuth
    ) {
      menu.push({
        label: "Listado Cajas",
        icon: "pi pi-fw pi-list",
        to: "/caja",
      });
    }
    if ("Servicio Crear" in permisosUsuarioAuth) {
      submenusItems.push({
        label: "Administración de Servicios",
        icon: "pi pi-fw pi-cog",
        to: "/servicio",
      });
    }
    if ("Costo Crear" in permisosUsuarioAuth) {
      submenusItems.push({
        label: "Registrar Pago de Servicios",
        icon: "pi pi-fw pi-money-bill",
        to: "/costo",
      });
    }

    if (submenusItems.length) {
      menu.push({
        label: "Gestión de Gastos Operativos/Costos",
        icon: "pi pi-fw pi-money-bill",
        items: submenusItems,
      });
    }
    if ("Banco Crear" in permisosUsuarioAuth) {
      menu.push({
        label: "Registro de Bancos",
        icon: "pi pi-fw pi-money-bill",
        to: "/banco",
      });
    }
    if ("ReporteMovBanco Listar" in permisosUsuarioAuth) {
      menu.push({
        label: "Ver Movimientos de Bancos",
        icon: "pi pi-fw pi-eye",
        to: "/movbanco",
      });
    }
    if (menu.length) {
      permisosAsignados.push({
        label: "CAJA",
        icon: "pi pi-wallet",
        items: menu,
      });
    }
    //FIN MÓDULO DE CAJAS

    // MODULO VENTAS

    menu = [];
    submenusItems = [];

    if ("Venta Crear" in permisosUsuarioAuth) {
      menu.push({
        label: "Nueva Venta",
        icon: "pi pi-fw pi-plus",
        to: "/nueva_venta",
      });
    }

    if (
      "Venta Listar" &&
      "Venta Crear" &&
      "Venta Ver Detalle" &&
      "Venta DescargarPDF" in permisosUsuarioAuth
    ) {
      menu.push({
        label: "Listado de Ventas",
        icon: "pi pi-fw pi-list",
        to: "/ventas",
      });
    }
    if ("Proforma Crear" in permisosUsuarioAuth) {
      menu.push({
        label: "Listado de Proformas",
        icon: "pi pi-fw pi-file",
        to: "/proformas",
      });
    }

    if (
      "Cliente Crear" in
      /* "Cliente Editar" &&
      "Cliente Ver Detalle" &&
      "Cliente Eliminar" */ permisosUsuarioAuth
    ) {
      submenusItems.push({
        label: "Listado Clientes",
        icon: "pi pi-list",
        to: "/clientes",
      });
    }
    if ("Venta Facturar" in permisosUsuarioAuth) {
      submenusItems.push({
        label: "Clientes Facturas",
        icon: "pi pi-file",
        to: "/clientes_facturas",
      });
    }
    if ("Cliente Pagos" in permisosUsuarioAuth) {
      submenusItems.push({
        label: "Administrar Pagos Clientes",
        icon: "pi pi-money-bill",
        to: "/clientespagos",
      });
    }
    if ("GrupoDesCliente Crear" in permisosUsuarioAuth) {
      submenusItems.push({
        label: "Grupos de Descuento Clientes",
        icon: "pi pi-users",
        to: "/grupo_desc_cliente",
      });
    }

    if (submenusItems.length) {
      menu.push({
        label: "Clientes",
        icon: "pi pi-fw pi-user-plus",
        items: submenusItems,
      });
    }

    if ("Comisiones Crear" in permisosUsuarioAuth) {
      menu.push({
        label: "Comisiones",
        icon: "pi pi-fw pi-wallet",
        to: "/comisiones",
      });
    }

    if (menu.length) {
      permisosAsignados.push({
        label: "VENTAS",
        icon: "pi pi-inbox",
        items: menu,
      });
    }
    // FIN MODULO VENTAS

    // MODULO FACTURACION

    menu = [];
    submenusItems = [];

    if (
      "PuntoVenta Crear" &&
      "PuntoVenta Editar" &&
      "PuntoVenta Eliminar" in permisosUsuarioAuth
    ) {
      menu.push({
        label: "Puntos de Venta",
        icon: "pi pi-fw pi-shopping-cart",
        to: "/punto_venta",
      });
    }

    if (
      "Dosificacion Crear" &&
      "Dosificacion Editar" &&
      "Dosificacion Eliminar" in permisosUsuarioAuth
    ) {
      menu.push({
        label: "Dosificaciones",
        icon: "pi pi-fw pi-file",
        to: "/dosificaciones",
      });
    }
    if ("R_Facturacion Listar" in permisosUsuarioAuth) {
      menu.push({
        label: "Facturas Emitidas",
        icon: "pi pi-file",
        to: "/filtros_facturas",
      });
    }
    if ("R_Facturacion Listar" in permisosUsuarioAuth) {
      menu.push({
        label: "Facturas Electrónicas Emitidas",
        icon: "pi pi-file",
        to: "/filtros_facturas_electronicas",
      });
    }

    if (menu.length) {
      permisosAsignados.push({
        label: "FACTURACION",
        icon: "pi pi-fw pi-file",
        items: menu,
      });
    }

    // FIN MODULO FACTURACION

    //MODULO DE REPORTES

    menu = [];
    submenusItems = [];

    if ("R_Productos de Productos" in permisosUsuarioAuth) {
      submenusItems.push({
        label: "Reporte de Productos",
        icon: "pi pi-box",
        to: "/reportes/productos",
      });
    }
    if ("R_FechaVence de FechaVence" in permisosUsuarioAuth) {
      submenusItems.push({
        label: "Fecha Vence Productos",
        icon: "pi pi-calendar-times",
        to: "/fechavence",
      });
    }
    if (submenusItems.length) {
      menu.push({
        label: "Reporte de Productos",
        icon: "pi pi-inbox",
        items: submenusItems,
      });
    }
    submenusItems = [];

    if ("R_Ventas de Ventas" in permisosUsuarioAuth) {
      submenusItems.push({
        label: "Reporte General Ventas",
        icon: "pi pi-chart-bar",
        to: "/reportes/ventas",
      });
    }

    if ("R_Ganancias de Ganancias" in permisosUsuarioAuth) {
      submenusItems.push({
        label: "Informe Ganancias",
        icon: "pi pi-inbox",
        to: "/reportes/ventas/ganancias",
      });
    }
    if ("R_Ganancias de Ganancias" in permisosUsuarioAuth) {
      submenusItems.push({
        label: "Informe Mensual y Gestión",
        icon: "pi pi-chart-line",
        to: "/reportes/ventas/informeMensualGestion",
      });
    }
    if ("R_Ventas de VentasCredito" in permisosUsuarioAuth) {
      submenusItems.push({
        label: "Verntas Crédito - Interes",
        icon: "pi pi-inbox",
        to: "/reportes/ventas/credito/interes",
      });
    }

    if (submenusItems.length) {
      menu.push({
        label: "Reporte de Ventas",
        icon: "pi pi-chart-line",
        items: submenusItems,
      });
    }

    if ("R_Compras de Compras" in permisosUsuarioAuth) {
      menu.push({
        label: "Reporte de Compras",
        icon: "pi pi-shopping-bag",
        to: "/reportes/compras",
      });
    }
    if ("R_Inversion de Inversion" in permisosUsuarioAuth) {
      menu.push({
        label: "Inversión en Negocio",
        icon: "pi pi-money-bill",
        to: "/reportes/inversionnegocio",
      });
    }

    if ("R_Stock de Stock" in permisosUsuarioAuth) {
      menu.push({
        label: "Reporte de Stock",
        icon: "pi pi-box",
        to: "/reportes/stocks",
      });
    }

    if ("R_Clientes de Clientes" in permisosUsuarioAuth) {
      menu.push({
        label: "Reporte de Clientes/Ventas",
        icon: "pi pi-users",
        to: "/reportes/clientes",
      });
    }
    if ("R_Cajas de Cajas" in permisosUsuarioAuth) {
      menu.push({
        label: "Reporte de Cajas",
        icon: "pi pi-wallet",
        to: "/reportes/cajas",
      });
    }

    if (menu.length) {
      permisosAsignados.push({
        label: "REPORTES",
        icon: "pi pi-fw pi-chart-bar",
        items: menu,
      });
    }
    //FIN MÓDULO DE REPORTES

    //MODULO DE Inventario
    menu = [];
    submenusItems = [];
    if ("Inventario ImportarStockInicial" in permisosUsuarioAuth) {
      menu.push({
        label: "Carga Inicial",
        icon: "pi pi-fw pi-upload",
        to: "/inventario/carga_inicial",
      });
    }
    if ("Inventario Movimientos" in permisosUsuarioAuth) {
      submenusItems.push({
        label: "Ingreso/Egreso",
        icon: "pi pi-list",
        to: "/movimientos",
      });
    }
    if ("Inventario Movimientos" in permisosUsuarioAuth) {
      submenusItems.push({
        label: "Listado de Movimientos",
        icon: "pi pi-print",
        to: "/MovAlmacenList",
      });
    }

    if (submenusItems.length) {
      menu.push({
        label: "Movimientos",
        icon: "pi pi-sync",
        items: submenusItems,
      });
    }

    if ("Inventario KardexProductos" in permisosUsuarioAuth) {
      menu.push({
        label: "Kardex de Productos",
        icon: "pi pi-box",
        to: "/kardex",
      });
    }
    if ("Inventario Traspasos" in permisosUsuarioAuth) {
      menu.push({
        label: "Traspaso de Productos",
        icon: "pi pi-arrows-h",
        to: "/traspasos",
      });
    }
    if ("Inventario Traspasos" in permisosUsuarioAuth) {
      menu.push({
        label: "Historial de Traspasos",
        icon: "pi pi-list",
        to: "/historial_traspasos",
      });
    }

    if (menu.length) {
      permisosAsignados.push({
        label: "INVENTARIO/KARDEX",
        icon: "pi pi-fw pi-box",
        items: menu,
      });
    }

    //Fin MODULO DE Inventario

    //MODULO DE CONFIGURACIONES
    menu = [];

    if (
      "Sucursal Editar" /* &&
      "Sucursal Empresa" &&
      "Sucursal Editar" &&
      "Sucursal Ver Detalle" &&
      "Sucursal Eliminar" */ in permisosUsuarioAuth
    ) {
      menu.push({
        label: "Empresa",
        icon: "pi pi-fw pi-building",
        to: "/configuracion/empresa",
      });

      menu.push({
        label: "Sucursales",
        icon: "pi pi-fw pi-building",
        to: "/sucursales",
      });
    }
    if (
      "Usuario Crear" &&
      "Usuario Editar" &&
      "Usuario Eliminar" &&
      "Usuario Ver Detalle" in permisosUsuarioAuth
    ) {
      menu.push({
        label: "Usuarios",
        icon: "pi pi-users",
        to: "/users",
      });
    }
    if ("Bitacoras Listar" in permisosUsuarioAuth) {
      menu.push({
        label: "Responables",
        icon: "pi pi-users",
        to: "responsables/listado",
      });
    }

    if (
      "Medico Crear" &&
      "Medico Editar" &&
      "Medico Eliminar" in permisosUsuarioAuth
    ) {
      menu.push({
        label: "Intermediarios",
        icon: "pi pi-id-card",
        to: "/medicos",
      });
    }
    if ("Rol Crear" in permisosUsuarioAuth) {
      menu.push({
        label: "Roles",
        icon: "pi pi-tags",
        to: "/roles",
      });
    }

    if ("Bitacoras Listar" in permisosUsuarioAuth) {
      menu.push({
        label: "Bitacora",
        icon: "pi pi-sync",
        to: "/bitacoras",
      });
    }

    if (menu.length) {
      permisosAsignados.push({
        label: "CONFIGURACIONES",
        icon: "pi pi-fw pi pi-fw pi-cog",
        items: menu,
      });
    }

    //FIN MÓDULO DE CONFIGURACIONES

    this.panelMenuitems = permisosAsignados;
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme
        ? "images/logo-white.svg"
        : "images/logo.svg";
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppConfig: AppConfig,
    AppFooter: AppFooter,
  },
};
</script>

<style lang="scss">
@import "./App.scss";
.p-panelmenu-header {
  .p-panelmenu-icon {
    color: #002236;
  }
}
</style>
