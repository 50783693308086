import { defineStore } from 'pinia';

const ruta_api = process.env.VUE_APP_ROOT_API;

export const useEmpresa = defineStore({
  id: 'empresa',
  state: () => ({
    empresa: localStorage.getItem('empresa') || ''
  }),
  actions: {
    async getEmpresa() {
      const res = await fetch(ruta_api +'/empresa', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const output = await res.json();
      if (output.success) {
        localStorage.setItem('empresa', output.data);
        this.empresa = output.data;
      } else {
        alert(output.message);
      }
    }
  }
});
